import "./index.css";
import "./App.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "./theme.css";

import React, { useEffect, useRef, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import r2wc from "@r2wc/react-to-web-component";
import { injectPrimereactStylesInShadowDOM } from "./utils";

import { StepperPanel } from "primereact/stepperpanel";
import { Stepper } from "primereact/stepper";
import { Card } from "primereact/card";

import Calculator from "./Calculator";
import ContactUs from "./ContactUs";

import strings from "./localization";

const webComponentName = "cocharge-profitability-calculator";

function App({ pricePerkWh = 0.59 }) {
  const stepperRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const locale = (navigator.language || navigator.userLanguage).split("-")[0];

  useEffect(() => {
    strings.setLanguage(locale);
  }, [locale]);

  useEffect(() => {
    // inject primerect styles to the shadow dom
    const shadowRoot = document.querySelector(webComponentName)?.shadowRoot;
    if (shadowRoot) {
      injectPrimereactStylesInShadowDOM(shadowRoot);
    }
  }, []);

  return (
    <PrimeReactProvider>
      <section className="min-w-full flex justify-center">
        <div className="min-w-2/3 mx-auto">
          <Card
            header={
              <h2 className="text-center mb-0">
                {locale === "de"
                  ? "Rentabilitätsrechner"
                  : "Profitability Calculator"}
              </h2>
            }
            className="pt-1"
          >
            <Stepper ref={stepperRef}>
              <StepperPanel header={locale === "de" ? "Rechner" : "Calculator"}>
                <Calculator pricePerkWh={pricePerkWh} stepperRef={stepperRef} />
              </StepperPanel>
              <StepperPanel
                header={locale === "de" ? "Kontaktiere uns" : "Contact us"}
              >
                <ContactUs
                  stepperRef={stepperRef}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                />
              </StepperPanel>
            </Stepper>
          </Card>
        </div>
      </section>
    </PrimeReactProvider>
  );
}

customElements.define(
  webComponentName,
  r2wc(App, {
    props: {
      priceperkwh: "string",
    },
    shadow: "open",
  })
);

export default App;
