
import InformationIcon from "./InformationIcon";

export default function ResultDisplayLine({ label, value, informationText = null }) {
  return (
    <div
      className="-mt-2 flex justify-content-between align-items-center"
      style={{
        fontWeight: 500,
        letterSpacing: "0.8px",
      }}
    >
      <span className="text-sm align-items-center lg:text-md">{label}</span>

      <span className="flex flex-row justify-content-center align-items-center">
        <h4
          style={{
            color: "var(--text-color-secondary)",
            fontSize: 18,
          }}
        >
          {value}
        </h4>
        {informationText && (
          <InformationIcon informationText={informationText} />
        )}
      </span>
    </div>
  );
}