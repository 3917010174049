import React from "react";
import { Checkbox } from "primereact/checkbox";

export default function CheckboxInputField({
  label = "Label",
  value,
  setValue,
}) {
  return (
    <div className="w-full mx-auto flex flex-row my-4 align-items-center justify-content-start">
      <Checkbox
        checked={value}
        value={value}
        onClick={setValue}
        />
        <p className="pl-2">{label}</p>
    </div>
  );
}
