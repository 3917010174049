import React, { useState, useMemo } from "react";
import { Button } from "primereact/button";

import NumberInputField from "../shared/NumberInputField";
import SliderInput from "../shared/SliderInput";
import CheckboxInputField from "../shared/CheckboxInput";
import AreaIconsSelectorSlider from "./components/AreaIconSelectorSlider";

import Divider from "../shared/Divider";

import ResultsDisplay from "./components/ResultsDisplay";
import PVResultsDisplay from "./components/PVResultsDisplay";

import strings from "../localization";
import { CalculationParams } from "../consts";

import { calculateResults } from "../utils";

const defaultAnnualChargingAmountPerCar = CalculationParams.kwhYearlyConsumptionPerCar || 4000;
const defaultProcurementPrice = CalculationParams.defaultProcurementPrice || 0.34;
const defaultDaysPerYear = CalculationParams.daysPerYear || 250;

function Calculator({ pricePerkWh, stepperRef }) {
  const [inputValues, setInputValues] = useState({
    sessionsNumber: 0,
    pvSupply: 0,
    hasPVSupply: false,
    chargingAmountAnnual: 0,
    procurementElectricityPricePerKwh: defaultProcurementPrice,
  });

  const _results = useMemo(
    () => calculateResults(inputValues, pricePerkWh),
    [inputValues, pricePerkWh]
  );

  return (
    <section className="w-full">
        <AreaIconsSelectorSlider
          label={strings.labels.sessionNumber}
          selected={inputValues?.sessionsNumber}
          setSelected={(val) =>
            setInputValues((pre) => ({ 
              ...pre,
              sessionsNumber: Number(val),
              chargingAmountAnnual: Number(val) * defaultAnnualChargingAmountPerCar
            }))
   
          }
        />

      <div className="surface-border mt-12 pt-4"/>

      <NumberInputField
          label={strings.labels.chargingAmountPerDay}
          value={inputValues?.chargingAmountAnnual/defaultDaysPerYear}
          max={100000}
          step={0.1}
          minFractionDigits={0}
          maxFractionDigits={1}
          setValue={(val) =>
            setInputValues((pre) => ({ ...pre, chargingAmountAnnual: Number(val)*defaultDaysPerYear, sessionsNumber: Math.trunc(Number(val*defaultDaysPerYear/defaultAnnualChargingAmountPerCar)) }))
          }
        />

       <NumberInputField
          label={strings.labels.chargingAmount}
          value={inputValues?.chargingAmountAnnual}
          max={100000}
          minFractionDigits={0}
          maxFractionDigits={0}
          step={1}
          setValue={(val) =>
            setInputValues((pre) => ({ ...pre, chargingAmountAnnual: Number(val), sessionsNumber: Math.trunc(Number(val/defaultAnnualChargingAmountPerCar)) }))
          }
        />

      <Divider/>

      <NumberInputField
          label={strings.labels.procurmentElectricityPrice}
          value={inputValues?.procurementElectricityPricePerKwh}
          max={50}
          min={0}
          step={0.01}
          setValue={(val) =>
            setInputValues((pre) => ({ ...pre, procurementElectricityPricePerKwh: Number(val), sessionsNumber: Math.trunc(Number(val/defaultAnnualChargingAmountPerCar)) }))
          }
      /> 

      <NumberInputField
          label={strings.labels.soldElectricityPrice}
          value={0.59}
          max={0.59}
          min={0.59}
          step={0.01}
          disabled
          setValue={(val) => console.log('updating price disabled')}
        /> 

      <Divider/>

      <ResultsDisplay
        pricePerkWh={pricePerkWh}
        revenuePerYear={_results.annualRevenueBeforeFees}
        resultPerYear={_results.annualRevenueAfterFees}
      />


      <Divider/>

      <CheckboxInputField
        label={strings.labels.stationUsesPV}
        value={inputValues.hasPVSupply}
        setValue={() =>
          setInputValues((pre) => ({ ...pre, hasPVSupply: !pre.hasPVSupply }))
        }
      />

      {inputValues.hasPVSupply && (
        <>
          <div className="card flex flex-column mt-6 gap-5 p-fluid ">
            <SliderInput
              id={"pvSupply"}
              label={strings.labels.pvSupply}
              onSliderChangeValue={(value) => setInputValues((pre) => ({ ...pre, pvSupply: value }))}
              value={inputValues.pvSupply}
            />
          </div>

          <Divider/>

          {inputValues.pvSupply !== 0 && (
            <PVResultsDisplay
              refundPerYearPV={_results.refundPVSupply}
              revenuePerYearWithPV={_results.annualRevenueWithPVRefund}
            />
          )}
        </>
      )}

      <div className="flex pt-4 justify-content-end">
        <Button
          label={strings.pointers.next}
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => stepperRef.current.nextCallback()}
        />
      </div>
    </section>
  );
}

export default Calculator;
