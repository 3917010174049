import React from "react";
import { InputNumber } from "primereact/inputnumber";

export default function NumberInputField({
  label = "Label",
  value,
  setValue,
  min = 0,
  step = 1,
  max = 100,
  minFractionDigits = 0,
  maxFractionDigits = 2,
  disabled = false,
}) {
  return (
    <div className="w-full mx-auto flex flex-row my-4 align-items-center justify-content-between">
      <p>{label}</p>

      <InputNumber
        disabled={disabled}
        value={value}
        onValueChange={(e) => setValue(e.value)}
        showButtons
        min={min}
        max={max}
        step={step}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        className="-mr-5"
        style={{ width: "35%" }}
      />
    </div>
  );
}
