import { useState } from "react";

export default function InformationIcon({ informationText }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="mx-2 relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <i
        id="information-tooltip"
        className="pi pi-info-circle cursor-pointer"
      ></i>
      {isHovered && (
        <div
          id="information-container"
          className="mx-2 absolute inline-block bg-gray-50 rounded-lg shadow-lg"
          style={{
            width: "300px",
            minHeight: "50px",
            maxHeight: "150px",
            overflowY: "auto",
            wordWrap: "break-word",
            wordBreak: "break-word",
            transform: "translateY(-40%)",
          }}
        >
          <p className="text-xs px-2">{informationText}</p>
        </div>
      )}
    </div>
  );
}
