import "./index.css";
import "./App.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "./theme.css";

import { PrimeReactProvider } from "primereact/api";
import React, { useEffect, useRef, useState } from "react";
import r2wc from "@r2wc/react-to-web-component";
import Calculator from "./calculator";
import Contactus from "./contactus";
import { StepperPanel } from "primereact/stepperpanel";
import strings from "./localization";
import { Stepper } from "primereact/stepper";
import { Card } from "primereact/card";

function App({ priceperkwh: pricePerkWh = 0.59 }) {
  const stepperRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState({
    sessionsNumber: 2,
    amountPerSession: 15,
    pvSupply: 0,
  });
  const onChangeValue = (key) => (e) => {
    setValues((pre) => ({ ...pre, [key]: e.value }));
  };
  useEffect(() => {
    strings.setLanguage(
      (navigator.language || navigator.userLanguage).split("-")[0]
    );
  }, []);
  return (
    <PrimeReactProvider>
      <div style={{ margin: "auto", width: "max-content" }}>
        <Card
          header={
            <h2 className="text-center mb-0   ">Profitabilty Calculator</h2>
          }
          className="pt-1"
        >
          <Stepper ref={stepperRef} style={{ flexBasis: "70rem" }}>
            <StepperPanel header={strings.calculator}>
              <Calculator
                values={values}
                onChangeValue={onChangeValue}
                pricePerkWh={+pricePerkWh}
                stepperRef={stepperRef}
              />
            </StepperPanel>
            <StepperPanel header={strings.contactUs}>
              <Contactus
                stepperRef={stepperRef}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </StepperPanel>
          </Stepper>
        </Card>
      </div>
    </PrimeReactProvider>
  );
}

customElements.define(
  "cocharge-profitability-calculator",
  r2wc(App, {
    props: {
      priceperkwh: "string",
    },
  })
);

export default App;
