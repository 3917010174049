import { InputMask } from "primereact/inputmask";
import { CalculatorParams } from "./consts";
import strings from "./localization";

export const getInputsData = (values, lang = "en") => {
  return [
    {
      key: "sessionsNumber",
      label: strings.sessionsNumberLabel,
      placeholder: strings.sessionsNumberPlaceholder,
      min: 1,
      max: 10,
      showButtons: true,
      addon: {
        children: <i className="pi pi-bolt"></i>,
      },
    },
    {
      key: "amountPerSession",
      label: `${strings.amountPerSessionLabel} (kWh)`,
      placeholder: strings.amountPerSessionPlaceholder,
      min: 15,
      max: 150,
      showButtons: true,
      addon: {
        children: "kWh",
      },
    },
    {
      key: "pvSupply",
      label: `${strings.solarPanelInput} (%)`,
      placeholder: strings.solarPanelInput,
      prefix: "%",
    },
  ];
};

const highlightedResultStyle = (highlight) => {
  return highlight
    ? {
        color: "var(--text-color-secondary)",
        fontSize: "18px",
      }
    : {
        color: "var(--text-color)",
        fontSize: "14px",
      };
};
export const getCalculatorResults = (values, lang = "en") => [
  {
    label: strings.chargingAmountPerYearLabel,
    value: `kWh ${values.chargingAmountPerYear}`,
    tooltip: strings.chargingAmountPerYearTooltip,
    valueStyle: highlightedResultStyle(false),
  },
  {
    label: strings.pricePerKWhLabel,
    value: `€ ${values.pricePerkWh.toFixed(2)}`,
    valueStyle: highlightedResultStyle(false),
  },
  {
    label: strings.revenuePerYearLabel,
    value: `€ ${values.revenuePerYear.toFixed(2)}`,
    tooltip: strings.revenuePerYearTooltip,
    valueStyle: highlightedResultStyle(!values.pvSupply),
  },
  {
    label: strings.resultPerYearLabel,
    value: `€ ${values.resultPerYear.toFixed(2)}`,
    valueStyle: highlightedResultStyle(!values.pvSupply),
  },
  values.pvSupply && {
    label: strings.revenuePerYearPVLabel,
    value: `€ ${values.revenuePerYearPV.toFixed(2)}`,
    valueStyle: { fontSize: 18 },
  },
  values.pvSupply && {
    label: strings.resultPerYearPVLabel,
    value: `€ ${values.resultPerYearPV.toFixed(2)}`,
    valueStyle: { fontSize: 18 },
  },
];

export const ContactusFields = [
  {
    key: "username",
    label: "Name",
    placeholder: "Name",
    keyfilter: "alpha",
  },
  {
    key: "company",
    label: "Company name",
    placeholder: "Name",
    keyfilter: "alpha",
  },
  {
    key: "email",
    label: "Email",
    placeholder: "Email",
  },
  {
    key: "phone",
    mask: "(+99) 999-9999-9999",
    label: "Telephone number",
    placeholder: "Telephone number",
    CustomInput: InputMask,
  },
];
