import strings from "../../localization";
import ResultDisplayLine from "../../shared/ResultDisplayLine";

export default function ResultsDisplay({
  revenuePerYear,
  resultPerYear,
}) {
  return (
    <div className="mt-4 pt-4 surface-border">
      <ResultDisplayLine
        label={strings.results.revenuePerYear}
        value={`€ ${revenuePerYear.toFixed(2)}`}
        informationText={strings.tooltips.revenuePerYear}
      />

      <ResultDisplayLine
        label={strings.results.incomePerYear}
        value={`€ ${resultPerYear.toFixed(2)}`}
        informationText={strings.tooltips.incomePerYear}
      />
    </div>
  );

}
