import { useEffect, useState } from "react";

const activeIconColor = "#215349";
const inactiveIconColor = "#dadbd9";

function CarIcon({ onClick, active }) {
  const [color, setColor] = useState(inactiveIconColor);

  useEffect(() => {
    const colorIcon = active ? activeIconColor : inactiveIconColor;
    setColor(colorIcon);
  }, [active]);

  return (
    <div
      className="cursor-pointer"
      onClick={onClick}
    >
      <i style={{ color }} className="pi pi-car" />
    </div>
  );
}


export default function AreaIconsSelectorSlider({ selected, setSelected, label="Label" }) {

  return (
    <>
    <p className="mb-6">{label}</p>
    <div className="flex flex-row justify-content-around align-items-center">
        <i className="pi pi-minus px-4 cursor-pointer" style={{ fontSize: '0.5rem' }} onClick={()=>setSelected(selected-1)}></i>
        {[...Array(10)].map((_, index) => (
        <CarIcon
          key={index}
          onClick={() => setSelected(index+1)}
          active={index < selected}
        />
      ))}
      <i className="pi pi-plus px-4 cursor-pointer" style={{ fontSize: '0.5rem' }} onClick={()=>setSelected(selected+1)}></i>
    </div>
    </>
  );
}
