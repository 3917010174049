import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    sessionsNumberLabel: "Number of charging sessions per day",
    sessionsNumberPlaceholder: "Number of charging sessions per day",
    amountPerSessionLabel: "Average amount per charging session",
    amountPerSessionPlaceholder: "Average amount per charging session",
    chargingAmountPerYearLabel: "Charging amount per year",
    chargingAmountPerYearTooltip:
      "charging sessions per day * average amount per charging session * (Price per kWh - Price for green electricity (34%)  + Cocharge fees (15%) ) * 250 days / year",
    pricePerKWhLabel: "Price per kWh of charging",
    revenuePerYearLabel: "Revenue per year",
    revenuePerYearTooltip:
      "Average amount per charging session * number of sessions * 250 days / year",
    resultPerYearLabel: "Result per year",
    calculator: "Calculator",
    contactUs: "Contact us",
    solarPanelLabel: "Solar Panel",
    solarPanelInput: "PV (photovoltaic) supply",
    revenuePerYearPVLabel: "Revenue per year with PV supply",
    resultPerYearPVLabel: "Result per year with PV supply",
  },
  de: {
    sessionsNumberLabel: "Anzahl der Ladesitzungen pro Tag",
    sessionsNumberPlaceholder: "Anzahl der Ladesitzungen pro Tag",
    amountPerSessionLabel: "Durchschnittliche Menge pro Ladesitzung",
    amountPerSessionPlaceholder: "Durchschnittliche Menge pro Ladesitzung",
    chargingAmountPerYearLabel: "Lademenge pro Jahr",
    chargingAmountPerYearTooltip:
      "Ladesitzungen pro Tag * durchschnittliche Menge pro Ladesitzung * (Preis pro kWh - Preis für Grünstrom (34%) + Cocharge-Gebühren (15%) ) * 250 Tage / Jahr",
    pricePerKWhLabel: "Preis pro kWh Laden",
    revenuePerYearLabel: "Einnahmen pro Jahr",
    revenuePerYearTooltip:
      "Durchschnittliche Menge pro Ladesitzung * Anzahl der Sitzungen * 250 Tage / Jahr",
    resultPerYearLabel: "Ergebnis pro Jahr",
    calculator: "Kalkulator",
    contactUs: "Kontaktiere uns",
    solarPanelLabel: "Solar Panel",
    solarPanelInput: "PV (Photovoltaik) Versorgung",
    revenuePerYearPVLabel: "Einnahmen pro Jahr mit PV-Versorgung",
    resultPerYearPVLabel: "Ergebnis pro Jahr mit PV-Versorgung",
  },
});

export default strings;
