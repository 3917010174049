export const CalculationParams = {
  cochargeFee: 0.15, // “CoCharge” fees (15% of the charging price)
  maintenanceFees: 0, // Maintenance of station (0% of the annual income)
  administartionFees: 0.2, // 20% of annual income
  pvRefundPercentage: 0.08, // 8% of the power, generated with the PV panel
  //
  daysPerYear: 250, // only workdays used in calculation
  defaultProcurementPrice: 0.34, // The electricity price bought by station owner
  kwhYearlyConsumptionPerCar: 4000, // on avarage a car would charge 4000 kWh per year
  costsForPublicStation: 3000, // 3000 euro per station annual cost
};
