import React from "react";

import { Button } from "primereact/button";
import { getCalculatorResults, getInputsData } from "./utils";
import { CalculatorParams } from "./consts";
import { Tooltip } from "primereact/tooltip";
import { Slider } from "primereact/slider";

function Calculator({ pricePerkWh, values, onChangeValue, stepperRef }) {
  const chargingAmountPerDay = values.amountPerSession * values.sessionsNumber;
  const revenuePerkWh =
    pricePerkWh -
    (pricePerkWh * CalculatorParams.fees +
      CalculatorParams.greenElectricityPrice);
  const revenuePerDay = chargingAmountPerDay * revenuePerkWh;

  const revenuePerYear = revenuePerDay * CalculatorParams.daysPerYear;
  const resultPerYear =
    revenuePerYear -
    revenuePerYear *
      (CalculatorParams.administartionFees + CalculatorParams.maintenanceFees);

  const chargingAmountPerYear =
    chargingAmountPerDay * CalculatorParams.daysPerYear;

  const pricePerkWhAfterCoChargeFees =
    pricePerkWh - pricePerkWh * CalculatorParams.fees;

  const pvSupplyKWH = (values.pvSupply * chargingAmountPerYear) / 100;

  const revenuePerYearPV =
    pvSupplyKWH *
    (pricePerkWhAfterCoChargeFees - CalculatorParams.feedinRefund);

  const resultPerYearPV = resultPerYear + revenuePerYearPV;

  const _values = {
    ...values,
    chargingAmountPerYear,
    resultPerYearPV,
    revenuePerYearPV,
    resultPerYear,
    revenuePerYear,
    pricePerkWh,
    chargingAmountPerDay,
  };

  const data = getInputsData(_values);

  return (
    <>
      <div className="card flex flex-column mt-3  gap-5 p-fluid ">
        {data.map(
          ({ label, key, tooltip, rightAddon, addon, ...input }, index) => (
            <div key={index}>
              <label htmlFor="stacked-buttons" className="font-bold block mb-4">
                {label}
              </label>
              <div className="flex align-items-center gap-3">
                <Slider
                  onChange={onChangeValue(key)}
                  {...input}
                  value={input.value || values[key]}
                  style={{ flex: 1 }}
                />
                <span>{input.value || values[key]}</span>
              </div>
            </div>
          )
        )}
      </div>
      <div>
        <div className="border-top-1 mt-7 pt-5 surface-border" />
        {getCalculatorResults(_values).map(
          ({ label, value, valueStyle, tooltip }) => (
            <div className="flex justify-content-between align-items-center gap-2">
              <span className="w-7">
                {label}{" "}
                {tooltip && (
                  <i
                    data-pr-tooltip={tooltip}
                    className="tooltip pi pi-info-circle text-blue-500	 relative"
                    style={{ top: 2 }}
                  ></i>
                )}
              </span>
              <span>
                <h4
                  style={{
                    color: "var(--text-color-secondary)",
                    ...valueStyle,
                  }}
                >
                  {value}
                </h4>
              </span>
            </div>
          )
        )}
      </div>
      <Tooltip target={".tooltip"} />
      <div className="flex pt-4 justify-content-end">
        <Button
          label="Next"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => stepperRef.current.nextCallback()}
        />
      </div>
    </>
  );
}

export default Calculator;
