import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { object, string } from "yup";
import { ContactusFields } from "../utils";

import strings from "../localization";

function ContactUs({ stepperRef, submitted, setSubmitted }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const onChangeValue = (key) => (e) => {
    const { value } = e.target;
    setValues({ ...values, [key]: value });
  };

  const submitForm = async () => {
    setErrors({});
    const schema = object().shape({
      username: string().required(),
      email: string().email().required(),
      city: string().required(),
      phone: string().required(),
      company: string(),
    });
    try {
      await schema.validate(values, { abortEarly: false });

      await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/143561006/560207fa-834b-419b-8406-13ab0eec25a3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: Object.keys(values).map((key) => ({
              name: key,
              value: values[key],
            })),
          }),
        }
      );
      setSubmitted(true);
    } catch (error) {
      const errors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
      }
      setErrors(errors);
    }
  };

  return (
    <section className="">
      {!submitted && (
        <div className="flex flex-column min-w-full">
          <div className="flex flex-column gap-3">
            {ContactusFields.map(({ CustomInput, ...field }) => (
              <div className="flex flex-column gap-3">
                <label>{field.label}</label>
                {CustomInput ? (
                  <CustomInput
                    {...field}
                    onChange={onChangeValue(field.key)}
                    invalid={errors[field.key]}
                  />
                ) : (
                  <InputText
                    {...field}
                    onChange={onChangeValue(field.key)}
                    invalid={errors[field.key]}
                  />
                )}
                <small style={{ width: 300 }} className="text-red-500">
                  {errors[field.key]}
                </small>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {submitted && (
        <div className="flex flex-column w-full align-items-center justify-center">
          <h2>{strings.contactSubmissionTitle}</h2>
          <p>{strings.contactSubmissionSubtitle}</p>
        </div>
      )}

      <div className="flex align-items-center mt-7 justify-content-between">
        <Button
          className="mr-8"
          label={strings.pointers.back}
          severity="secondary"
          icon="pi pi-arrow-left"
          onClick={() => stepperRef.current.prevCallback()}
        />
        {!submitted && (
          <Button
            className="ml-8"
            label={strings.pointers.submit}
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={submitForm}
          />
        )}
      </div>
    </section>
  );
}

export default ContactUs;
