import strings from "../../localization";
import ResultDisplayLine from "../../shared/ResultDisplayLine";

export default function PVResultsDisplay({
  refundPerYearPV,
 revenuePerYearWithPV,
}) {
  return (
    <div className="mt-6">
    <ResultDisplayLine label={strings.results.refundPVPerYear} value={`€ ${refundPerYearPV.toFixed(2)}`} informationText={strings.tooltips.pvRefund}/>
    <ResultDisplayLine label={strings.results.revenuePVPerYear} value={`€ ${revenuePerYearWithPV.toFixed(2)}`} informationText={strings.tooltips.pvRevenue}/>
    </div>
  );
}
