import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { object, string } from "yup";
import { ContactusFields } from "./utils";

function Contactus({ stepperRef, submitted, setSubmitted }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const onChangeValue = (key) => (e) => {
    const { value } = e.target;
    setValues({ ...values, [key]: value });
  };

  const submitForm = async () => {
    setErrors({});
    const schema = object().shape({
      username: string().required(),
      email: string().email().required(),
      phone: string().required(),
      company: string(),
    });
    try {
      await schema.validate(values, { abortEarly: false });

      await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/143561006/560207fa-834b-419b-8406-13ab0eec25a3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: Object.keys(values).map((key) => ({
              name: key,
              value: values[key],
            })),
          }),
        }
      );
      setSubmitted(true);
    } catch (error) {
      const errors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
      }
      setErrors(errors);
    }
  };

  return (
    <>
      {!submitted && (
        <div className="flex flex-column w-full   ">
          <div className="flex flex-column   gap-3">
            {ContactusFields.map(({ CustomInput, ...field }) => (
              <div className="flex flex-column gap-3">
                <label>{field.label}</label>
                {CustomInput ? (
                  <CustomInput
                    {...field}
                    onChange={onChangeValue(field.key)}
                    invalid={errors[field.key]}
                  />
                ) : (
                  <InputText
                    {...field}
                    onChange={onChangeValue(field.key)}
                    invalid={errors[field.key]}
                  />
                )}
                <small style={{ width: 300 }} className="text-red-500">
                  {errors[field.key]}
                </small>
              </div>
            ))}
          </div>
        </div>
      )}
      {submitted && (
        <div className="flex flex-column w-full align-items-center justify-center">
          <h2>Thank you for contacting us</h2>
          <p>We will get back to you soon</p>
        </div>
      )}
      <div className="flex mt-7 justify-content-between">
        <Button
          label="Back"
          severity="secondary"
          icon="pi pi-arrow-left"
          onClick={() => stepperRef.current.prevCallback()}
        />
        {!submitted && (
          <Button
            label="Submit"
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={submitForm}
          />
        )}
      </div>
    </>
  );
}

export default Contactus;
